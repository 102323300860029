// 信用账单
<template>
  <div class="sld_credit_list">
    <!-- <MemberTitle :memberTitle="L['信用账单']"></MemberTitle> -->
    <div class="container">
      <h3>{{ L["信用账单"] }}</h3>

      <template v-if="memberInfo.memberType == 3">
        <div class="sld_order_nav">
          <div class="sld_order_nav_con flex_row_start_center pointer">
            <div :class="{item:true,active:current_state==''}" @click="changeState('')">{{L['全部']}}</div>
            <div :class="{item:true,active:current_state==10}" @click="changeState(10)">{{L['待结算']}}</div>
            <div :class="{item:true,active:current_state==20}" @click="changeState(20)">{{L['待审核']}}</div>
            <div :class="{item:true,active:current_state==30}" @click="changeState(30)">{{L['已结算']}}</div>
            <div :class="{item:true,active:current_state==40}" @click="changeState(40)">{{L['已逾期']}}</div>
          </div>
          <div class="search_con flex_row_start_center" v-if="selectList.data.length>0">
            <el-select v-model="selectTime" placeholder="请选择" @change="changeTime">
              <el-option v-for="item in selectList.data" :key="item" :value="item.id" :label="item.name"></el-option>
            </el-select>
            <div class="search_incon flex_row_start_center">
              <el-input class=" search_input" v-model="keyword" placeholder="信用账单号/采购单号/订单号" clearable @clear="clear">
              </el-input>
              <div class="search pointer" @click="getOrderList">{{L['搜索']}}</div>
            </div>
          </div>
        </div>
        <div style="position:relative;" class="flex_row_start_start">
          <div class="box" :style="order_list.data.length>0?'overflow-x:auto;':'overflow-x:hidden;'">
            <template v-if="current_state=='' || current_state=='20' || current_state=='30'">
              <div class="box8 flex-col" :style="current_state=='30'?'width:1176px;overflow:hidden;':''">
                <div class="box9 flex-row">
                  <span class="txt18">订单号</span>
                  <span class="txt18 txt19">采购单号</span>
                  <span class="txt18 word30">账单生成时间</span>
                  <span class="txt18 txt20">最后结算日</span>
                  <span class="txt18 txt21">账单金额</span>
                  <span class="txt18 word31">应结算金额</span>
                  <span class="txt18 word32">结算状态</span>
                  <span class="txt18 word33">汇款信息</span>
                  <!-- <span class="txt18 word34" v-if="current_state != '30'">操作</span> -->
                </div>
              </div>
              <template v-if="order_list.data.length>0">
                <template v-for="item in order_list.data" :key="item.payId">
                  <div class="group9 flex-col" :style="current_state=='30'?'width:1176px;overflow:hidden;':''">
                    <div class="layer30 flex-col">
                      <div class="group10 flex_row_start_center">
                        <i class="iconfont icondianpu icon9" @click="storeInfo(item.storeId)"></i>
                        <span class="info14" @click="storeInfo(item.storeId)">{{item.storeName}}</span>
                        <i class="iconfont iconxiaoxiliebiao label7" @click="chatInfo(item.storeId)"></i>
                        <span class="word35">信用账单号：{{item.billPaySn}}</span>
                        <span class="word36">发起时间：{{item.createTime}}</span>
                      </div>
                    </div>
                    <div class="layer31 flex-row">
                      <div class="outer2">
                        <span v-for="items in item.billList" :key="items.billId" class="txt22 txt222"
                          @click="orderInfo(items.orderSn)">{{items.orderSn}}</span>
                      </div>
                      <div class="outer2 outer3">
                        <span v-for="items in item.billList" :key="items.billId"
                          class="txt22 txt23">{{items.purchaseSn?items.purchaseSn:'--'}}</span>
                      </div>
                      <div class="outer2 outer4">
                        <span v-for="items in item.billList" :key="items.billId"
                          class="txt22 word39">{{items.createTime}}</span>
                      </div>
                      <div class="outer2 outer5">
                        <span v-for="items in item.billList" :key="items.billId" class="info15">
                          {{items.overdueTime}}<span v-if="items.overdueState"
                            class="word42">{{items.overdueState?items.overdueState:'即将逾期'}}</span>
                        </span>
                      </div>
                      <div class="outer2 outer7">
                        <span v-for="items in item.billList" :key="items.billId"
                          class="txt22 txt24">¥{{items.billAmount}}</span>
                      </div>
                      <div class="outer8"></div>
                      <div class="txt25">¥{{item.totalAmount}}</div>
                      <div class="outer9 flex-col"></div>
                      <span class="word44">{{item.stateValue}}</span>
                      <div class="outer10 flex-col"></div>
                      <div class="outer11 flex-col">
                        <span class="word45" v-if="item.state != 1">汇款时间：{{item.payTime ? item.payTime : '--'}}</span>
                        <span class="word45" v-else>--</span>
                        <span class="word45" v-if="item.auditTime">确认时间：{{item.auditTime}}</span>
                        <div class="main6 flex-col" v-show="item.payVoucher">
                          <el-image class="img1" referrerpolicy="no-referrer" :src="item.payVoucher"
                            :preview-src-list="[item.payVoucher]" />
                        </div>
                      </div>
                      <!-- <div class="outer12 flex-col"></div> -->
                      <!-- <div class="outer13 flex-col" v-if="current_state != '30'">
                      <span class="txt26" v-if="item.state != 3" @click="pay(item.billPaySn)">结算</span>
                      <span class="txt26" v-if="item.state==1 && item.billList.length>1" @click="splitList(item.payId)">取消合并</span>
                    </div> -->
                    </div>
                  </div>
                </template>
                <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
                  :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
                  :hide-on-single-page="false" class="flex_row_end_center"></el-pagination>
              </template>
              <template v-else>
                <SldCommonEmpty tip="暂无信用账单数据" totalWidth="934px" />
              </template>
            </template>
            <template v-else>
              <div class="mod8 flex-col">
                <div class="box9 flex-row">
                  <span class="word34">订单号</span>
                  <span class="word34 word35">采购单号</span>
                  <span class="word34 info12">账单生成时间</span>
                  <span class="word34 txt11">最后结算日</span>
                  <span class="word34 txt12">账单金额</span>
                  <span class="word34 info13">应结算金额</span>
                  <span class="word34 word36">结算状态</span>
                  <span class="word34 word37">操作</span>
                </div>
              </div>
              <template v-if="order_list.data.length>0">
                <div class="mod9 flex-col">
                  <template v-for="item in order_list.data" :key="item.payId">
                    <div class="outer3 flex-col">
                      <div class="outer4 flex-col">
                        <div class="section7 flex_row_start_center">
                          <div class="bd32">
                            <el-checkbox v-model="item.ischeck" @change="changeSelect"></el-checkbox>
                          </div>
                          <span class="info14" @click="storeInfo(item.storeId)">{{item.storeName}}</span>
                          <i class="iconfont iconxiaoxiliebiao label6" @click="chatInfo(item.storeId)"></i>
                          <span class="word35">信用账单号：{{item.billPaySn}}</span>
                          <span class="word36">发起时间：{{item.createTime}}</span>
                        </div>
                      </div>
                      <div class="outer5 flex-row">
                        <span class="word38">
                          <span class="word38_order" v-for="items in item.billList" :key="items.billId"
                            @click="orderInfo(items.orderSn)">{{items.orderSn}}</span>
                        </span>
                        <span class="word38 word39">
                          <span v-for="items in item.billList"
                            :key="items.billId">{{items.purchaseSn?items.purchaseSn:'--'}}</span>
                        </span>
                        <span class="word38 word40">
                          <span v-for="items in item.billList" :key="items.billId">{{items.createTime}}</span>
                        </span>
                        <span class="word38 word40">
                          <span v-for="items in item.billList" :key="items.billId">
                            <span>{{items.overdueTime}}</span>
                            <span v-if="items.overdueState"
                              class="info16">{{items.overdueState?items.overdueState:'即将逾期'}}</span>
                          </span>
                        </span>
                        <span class="word38 word41">
                          <span v-for="items in item.billList" :key="items.billId">¥{{items.billAmount}}</span>
                        </span>
                        <span class="word42">¥{{item.totalAmount}}</span>
                        <span class="word43">{{item.stateValue}}</span>
                        <div class="section8 flex-col"
                          v-if="(item.state==1 && item.billList.length>1) || item.state!=3">
                          <span class="info15" v-if="item.state!=3" @click="pay(item.billPaySn)">结算</span>
                          <span class="info15" v-if="item.state==1 && item.billList.length>1"
                            @click="splitList(item.payId)">取消合并</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
                  :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
                  :hide-on-single-page="false" class="flex_row_end_center"></el-pagination>
                <SldCommonEmpty v-if="order_list.data.length==0" :tip="L['暂无订单～']" totalWidth="934px" />
              </template>
              <template v-else>
                <SldCommonEmpty tip="暂无信用账单数据" totalWidth="934px" />
              </template>
            </template>
          </div>
          <div class="box2" v-if="current_state=='' || current_state=='20'">
            <div class="box8">操作</div>
            <template v-for="item in order_list.data" :key="item.payId">
              <div class="group9">
                <div class="layer30"></div>
                <div class="layer31 flex-row">
                  <div class="outer13 flex-col" v-if="current_state != '30'">
                    <span class="txt26" v-if="item.state != 3" @click="pay(item.billPaySn)">结算</span>
                    <span class="txt26" v-if="item.state==1 && item.billList.length>1"
                      @click="splitList(item.payId)">取消合并</span>
                  </div>
                  <div class="outer11 flex-col">
                    <span class="word45" v-if="item.state != 1">汇款时间：{{item.payTime ? item.payTime : '--'}}</span>
                    <span class="word45" v-else>--</span>
                    <span class="word45" v-if="item.auditTime">确认时间：{{item.auditTime}}</span>
                    <div class="main6 flex-col" v-show="item.payVoucher">
                      <el-image class="img1" referrerpolicy="no-referrer" :src="item.payVoucher" />
                    </div>
                  </div>
                  <div class="outer12">
                    <span v-for="items in item.billList" :key="items.billId" class="txt24">¥{{items.billAmount}}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="section2 flex-col">
          <div class="section3 flex-col">
            <div class="main5 flex-col">
              <img class="pic1" referrerpolicy="no-referrer" :src="attestation" />
            </div>
            <span class="info13">您尚未认证成为企业用户，认证后即可享受信用支付服务。</span>
            <div class="main6 flex-col" @click="toAuth">
              <span class="word23">去认证</span>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- 操作按钮 start -->
    <div v-if="(current_state==10 || current_state==40) && order_list.data.length>0"
      class="options_btn flex_row_between_center" ref="optionsBtn">
      <div class="options_btn_left flex_row_start_center">
        <div class="options_sel">
          <el-checkbox v-model="checkAll" @change="changeAll"></el-checkbox>
          <span>{{ L["全选"] }}</span>
        </div>
      </div>
      <div class="options_right flex_row_start_center">
        <div class="options_sel_num">
          {{ L["已选择"] }} {{applyList.payIds.length}} 个订单
        </div>
        <div class="go_buy cursor_pointer" :class="{active:applyList.payIds.length>0}" @click="merge">
          去结算
        </div>
      </div>
    </div>
    <!-- 操作按钮 end -->

  </div>
</template>

<script>
  import { ref, reactive, getCurrentInstance, onMounted } from "vue";
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import MemberTitle from "@/components/MemberTitle";
  import SldCommonEmpty from '@/components/SldCommonEmpty';
  import { ElMessage } from 'element-plus';
  export default {
    name: "credit-list",
    components: {
      MemberTitle,
      SldCommonEmpty
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const router = useRouter();
      const route = useRoute();
      const store = useStore();
      const memberInfo = ref(store.state.memberInfo);
      const current_state = ref(""); //当前列表订单状态
      const keyword = ref('');
      const selectTime = ref('0');
      const selectList = reactive({ data: [] });
      const checkAll = ref(false);
      const order_list = reactive({ data: [] });
      const applyList = reactive({
        payIds: [],
        paySn: [],
        ids: '/'
      }); //合并提交的数据
      const attestation = require('@/assets/persen_attestation.png');
      const pageData = reactive({
        current: 1,
        pageSize: 10,
        total: 0
      });

      //改变订单状态
      const changeState = (orderState) => {
        if (current_state.value != orderState) {
          current_state.value = orderState;
          pageData.current = 1;
          getOrderList();
          checkAll.value = false;
          applyList.payIds = [];
          applyList.paySn = [];
          applyList.ids = '/';
        }
      };

      const clear = () => {
        keyword.value = '';
        getOrderList();
      };

      //获取订单列表
      const getOrderList = () => {
        let state = null;
        switch (current_state.value) {
          case 10: state = 1; break;
          case 20: state = 2; break;
          case 30: state = 3; break;
          case 40: state = 4; break;
        }
        var param = {
          current: pageData.current,
          pageSize: pageData.pageSize,
        };
        if (keyword.value) { //关键词(信用账单号/采购单号/订单号)
          param.keyword = keyword.value;
        }
        if (state) { //结算状态：1-待结算；2-待审核；3-已结算；4-已逾期
          param.state = state;
        }
        if (selectTime.value) { //时间：1-近3个月账单；2-今年内账单；3-xx年以前账单
          param.time = selectTime.value;
        }
        proxy
          .$get("v3/member/front/member/credit/bill/list", param)
          .then(res => {
            if (res.state == 200) {
              if (res.data.list.length > 0) {
                let totalNum = 0;
                res.data.list.forEach(item => {
                  if (applyList.ids && applyList.ids.indexOf('/' + item.payId + '/') != -1) {
                    item.ischeck = true;
                    totalNum++;
                  } else {
                    item.ischeck = false;
                  }
                })
                if (totalNum == res.data.list.length) {
                  checkAll.value = true;
                }
              }
              order_list.data = res.data.list;
              pageData.total = res.data.pagination.total
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };

      const getTime = () => {
        proxy
          .$get("v3/member/front/member/credit/bill/timeList")
          .then(res => {
            if (res.data.length > 0) {
              selectList.data = res.data;
              selectTime.value = selectList.data[0].id;
            }
          })
      };

      const changeTime = () => {
        getOrderList();
      };

      const storeInfo = (id) => { //店铺首页
        router.push({
          path: '/store/index',
          query: {
            vid: id
          }
        })
      };

      const chatInfo = (id) => {
        let newWin = router.resolve({
          path: '/service',
          query: {
            vid: id
          }
        })
        window.open(newWin.href, '_blank');
      }

      const changeAll = () => { //全选切换
        checkSelect(checkAll.value ? 1 : 2);
      }
      const changeSelect = () => { //商品选择切换
        checkSelect();
      }
      const checkSelect = (type) => { //筛选选中的商品 type: 1 全选 2 全不选
        let num = 0;

        let arr = applyList.payIds;
        let paySn = applyList.paySn;
        let idsArr = applyList.ids.split('/');
        let ids = applyList.ids;

        order_list.data.forEach(item => {
          let index = idsArr.indexOf(item.payId.toString());

          if (type == 1) {
            item.ischeck = true;
            num++;
            if (index == -1) {
              arr.push(item.payId);
              paySn.push(item.billPaySn);
              ids += item.payId + '/'
            }
          } else if (type == 2) {
            item.ischeck = false;
            if (index > 0) {
              arr.splice(index - 1, 1);
              paySn.splice(index - 1, 1);
              idsArr.splice(index - 1, 1);
            }
            ids = ids.replace('/' + item.payId + '/', '/');
          } else if (item.ischeck) {
            num++;
            if (index == -1) {
              arr.push(item.payId);
              paySn.push(item.billPaySn);
              ids += item.payId + '/'
            }
          } else {
            if (index > 0) {
              arr.splice(index - 1, 1);
              paySn.splice(index - 1, 1);
              idsArr.splice(index - 1, 1);
            }
            ids = ids.replace('/' + item.payId + '/', '/');
          }
        })
        if (!type) {
          checkAll.value = (num == order_list.data.length ? true : false)
        }
        applyList.payIds = arr;
        applyList.paySn = paySn;
        applyList.ids = ids;
      };

      const splitList = (id) => { //取消合并
        let param = {
          payId: id  //账单id
        }
        proxy
          .$post('v3/member/front/member/credit/bill/cancel', param)
          .then(res => {
            if (res.state == 200) {
              ElMessage.success(res.msg);
              getOrderList();
            } else {
              ElMessage.error(res.msg);
            }
          })
      };

      const pay = (id) => { //单个结算
        router.push({
          path: '/member/credit/pay',
          query: {
            paySn: id
          }
        });
      };

      const merge = () => { //合并结算
        if (applyList.payIds.length == 0) {
          return;
        } else if (applyList.payIds.length == 1) {
          router.push({
            path: '/member/credit/pay',
            query: {
              paySn: applyList.paySn[0]
            }
          });
        } else {
          router.push({
            path: '/member/credit/pay',
            query: {
              payIds: applyList.payIds.join(',')
            }
          });
        }
      };

      const toAuth = () => { //去认证
        router.push(`/member/authen`);
      };

      const orderInfo = (orderSn) => {
        router.push({
          path: '/member/order/info',
          query: {
            orderSn: orderSn
          }
        })
      };

      //页数改变
      const handleCurrentChange = e => {
        pageData.current = Math.floor(e);
        checkAll.value = false;
        getOrderList();
      };

      onMounted(() => {
        if (route.query.keywords) {
          keyword.value = route.query.keywords;
        }
        getTime();
        getOrderList();
      });

      return {
        L,
        memberInfo,
        current_state,
        changeState,
        clear,
        getOrderList,
        keyword,
        selectTime,
        selectList,
        getTime,
        changeTime,
        storeInfo,
        chatInfo,
        changeAll,
        changeSelect,
        checkSelect,
        order_list,
        applyList,
        splitList,
        pay,
        merge,
        checkAll,
        toAuth,
        attestation,
        orderInfo,
        pageData,
        handleCurrentChange,
      };
    },
  };
</script>
<style lang="scss">
  @import "../../../style/credit/index.scss";
</style>